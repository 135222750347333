import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

const AboutHero = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "richard.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.image.childImageSharp.fluid
      return (
        <BackgroundImage className={className} fluid={image}>
          <div className="about-hero-text">
            <div className="text-wrap-about">
              <p>
                Richard Weale is a participant in the Amazon Services Associates
                Program, an affiliate advertising program designed to provide a
                means for sites to earn advertising fees by advertising and
                linking to amazon.com, etc. from qualifying purchases.
              </p>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(AboutHero)`
  width: 100%;
  height: calc(100vh - 75px);
  min-height: 850px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
`

export default StyledBackgroundSection
